//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    VueSlickCarousel,
  },
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    let slidesToShow = 3
    return {
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: slidesToShow,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
          },
          {
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState([ 'user', 'menu', 'orderHistory', 'order' ]),
    items() {
      if (!this.orderHistory) return [];

      return this.orderHistory.flatMap(order => 
        order.products.flatMap(product => {
          if (!this.menu) return []

          const categoryProduct = this.menu.categories
            ?.flatMap(category => 
              category.products.find(catProduct => catProduct.name === product.name)
            )
            ?.filter(Boolean)
            .filter(catProduct => catProduct.images?.length > 0);

          return categoryProduct.map(catProduct => ({
            name: catProduct.name,
            id: catProduct.id,
            sku: catProduct.sku,
            images: catProduct.images,
            choices: product.choices,
            total: order.totals.total
          }));
        })
      );
    },
    showOrderAgainSection() {
      return this.user && this.menu && this.orderHistory?.length > 0 && this.items?.length > 0;
    }
  },
  async mounted() {
    if (this.user) {
      await this.$api.getRecentOrders()
    }
  },
  methods: {
    getImage(p) {
      let img = null
      p.images?.map(i => {
        if(i.groupname == 'mobile-webapp-menu') {
          img = i.filename
        }
      })
      return this.menu.imagepath + img
    },
    async addItem(p) {
      let product = await this.$api.getProduct(p.sku)
      let addProduct = {
        id: p.id,
        quantity: 1,
        choices: this.getModifiers([], p.choices, product.modifiers)
      }
      if(!this.order) {
        await this.$api.createOrder()
      }
      await this.$api.addToOrder([addProduct])
    },
    getModifiers(choices, selects, mods) {
      mods.map(m => {
        m.options.map(o => {
          selects.map(s => {
            if(s.name == o.name) {
              choices.push({
                id: o.id,
                quantity: s.quantity
              })
              if(o.modifiers) {
                choices = this.getModifiers(choices, selects, o.modifiers)
              }
            }
          })
        })
      })
      return choices
    }
  }
}
